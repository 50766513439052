import React, { useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import ModalContext from '../store/modalContext'
// import { ModalWrapper, ModalInner } from './Modal.css'

export const fadeInUp = keyframes`
  from {
    top: 2rem;
    opacity: .25;
  }
  to {
    top: 0;
    opacity: 1;
  }
`

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
	background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  z-index: 100000;
  @media (max-width: 668px) {
    z-index: 0;
    display: none;
  }
`

export const ModalInner = styled.div`
  /* background: white; */
  position: relative;
  max-width: 100%;
  max-height: 100%;
  height: 80%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
	animation: ${fadeInUp} 0.3s;
	transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	font-size: 5rem;
        font-weight: 900;
  z-index: 200000
`

const Modal = ({ open, imageUrl }) => {
  const handleLifeCycle = () => {
    // eslint-disable-next-line no-console
    // console.log('Lifecycle methods?  F*ck yeah.')
  }
  // Each time open prop changes, run handleLifeCycle()
  useMemo(handleLifeCycle, [open])

  return (
    <ModalContext.Consumer>
      {({ closeModal, imageUrl }) =>
        open ? (
          <div className='modal'>
            <ModalWrapper onClick={closeModal}>
              <ModalInner>
                {console.log(imageUrl.caption)}
                <Img
                  loading='lazy'
                  fluid={imageUrl.asset.fluid}
                />
              </ModalInner>
              <h4>{imageUrl.caption} | <span> Foto: {imageUrl.alt}</span></h4>
            </ModalWrapper>
          </div>
        ) : null
      }
    </ModalContext.Consumer>
  )
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired
}

export default Modal
