import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faIdBadge } from '@fortawesome/free-solid-svg-icons'
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons'
import Figure from './figure'
import Slideshow from './slideshow'

import typography from '../typography.module.css'

const serializers = {
  marks: {
    internalLink: (props) => {
      const { slug = {} } = props.mark
      const href = `/${slug.current}`
      return <a href={href}>{props.children}</a>
    },
    link: (props) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = props.mark
      return blank
        ? <a href={href} target='_blank' rel='noopener'>{props.children}</a>
        : <a href={href}>{props.children}</a>
    }
  },
  types: {
    block (props) {
      switch (props.node.style) {
        case 'h1':
          return <h1 className={typography.responsiveTitle1}>{props.children}</h1>

        case 'h2':
          return <h2 className={typography.responsiveTitle2}>{props.children}</h2>

        case 'h3':
          return <h3 className={typography.responsiveTitle3}>{props.children}</h3>

        case 'h4':
          return <h4 className={typography.responsiveTitle4}>{props.children}</h4>

        case 'blockquote':
          return <blockquote className={typography.blockQuote}>{props.children}</blockquote>

        case 'list':
          return <ul className='fa-ul'>
            <li><FontAwesomeIcon icon={faBadgeCheck} listItem />{props.children}</li></ul>

        default:
          return <p className={typography.paragraph}>{props.children}</p>
      }
    },
    figure (props) {
      return <Figure {...props.node} />
    },
    slideshow (props) {
      return <Slideshow {...props.node} />
    }
  }
}

const BlockContent = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

export default BlockContent
