import React from 'react'
import Menu from './menu'

const Header = ({ onHideNav, onShowNav, showNav, siteTitle, isBurger }) => (
  <div className='header white'>
    <div className='header-wrapper'>
      <Menu />
    </div>
  </div>
)

export default Header
