import React from 'react'
import Header from './header'
import Footer from './footer'
import Scroll from './scroll/index'
// import Modal from './modal'
import '../styles/layout.css'
import ModalContext from './store/modalContext'
import Modal from './modal/Modal'

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle, pageLayout }) => (
  <>
    <div id='siteWrapper' className='clearfix'>
      <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
      <Scroll showBelow={1200} siteTitle={siteTitle} css='position: fixed; right: 1.5em; bottom: 1em;' />
      {/* <Modal /> */}
      <main id='page' className='container'>
        {children}
      </main>
      <Footer pageLayout={pageLayout} />
      <ModalContext.Consumer>
        {({ open }) => {
          return <Modal open={open} />
        }}
      </ModalContext.Consumer>
    </div>
  </>
)

export default Layout
