import styled from 'styled-components'
import { FaArrowDown as Down } from 'react-icons/fa';
import {FiArrowUp as Up} from 'react-icons/fi'
// import { ArrowDown as Down } from 'styled-icons/evil/ArrowDown'
// import { ArrowUp as Up } from 'styled-icons/evil/ArrowUp'

export const Arrow = styled(Down).attrs(({ direction, size }) => ({
  as: direction === `up` && Up,
  size,
}))`
  ${({ theme, show, size }) => `
  z-index: 2;
  /* background: #333; */
  color: #fff;
  border-radius: 50%;
  transition: 0.6s;
  position: fixed;
  bottom: 2.5em;
  opacity: ${show ? 1 : 0};
  visibility: ${show ? `visible` : `hidden`};
  :hover {
    transform: scale(1.25);
    /* background: rgba(256,256,256,0); */
  }
  right: calc(5vw - ${size} / 1);`}
`