import React, { useState, useContext } from 'react'
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { cn } from '../lib/helpers'
import { FaInstagram, FaFacebookSquare } from 'react-icons/fa'
import styles from './header.module.css'
// import Logo from '../svg/new_logo.svg'
import Logo from '../svg/new_logo_morespace.svg'

const Menu = () => {
  const [isBurger, SetisBurger] = useState(false)

  const handleClick = () => {
    SetisBurger(value => !value)
  }

  const menuClassName = `hamburger-button ${isBurger ? 'is-active' : ''}`
  const mobileMenuClassName = `hamburger-button-cross ${isBurger ? 'is-active' : ''}`
  const mobileMenu = `${isBurger ? 'mobile-menu-active' : 'mobile-menu'}`

  return (
    <nav className='header-nav'>

      <div className={styles.wrapper}>

        <div className={styles.brandingNavWrapper}>
          <div className={styles.branding}>
            <Link to='/'><Logo /></Link>
          </div>
          {/* <div className='main-menu'> */}
          <nav className={styles.nav}>
            <ul className='main-menu'>
              <li className='has-drop-down'>
                <Link to='/'>LEISTUNGEN<br /><span>für Privatpersonen</span></Link>

                <ul className='dropdown' aria-label='submenu'>
                  <li><Link to='/zweithaar/'>ZWEITHAAR & HAARERSATZ</Link></li>
                  <li><Link to='/wedding/'>HOCHZEITS- & FESTSTYLING</Link></li>
                  <li><Link to='/special/'>MAKE UP & SPECIAL EFFECTS - für Halloween, Fasching usw.</Link></li>
                  <li><Link to='/fotoshoots/'>FOTOSHOOTS</Link></li>
                  <li><Link to='/kurse/'>KURSE & WORKSHOPS</Link></li>
                </ul>
              </li>

              <li className='has-drop-down'>
                <Link to='/'>LEISTUNGEN<br /><span>für Theater, Show & Film</span></Link>

                <ul className='dropdown' aria-label='submenu'>
                  <li><Link to='/herstellung/'>HERSTELLUNG</Link></li>
                  <li><Link to='/verleih/'>VERLEIH</Link></li>
                  <li><Link to='/design/'>DESIGN, ERSTELLUNG & ENTWICKLUNG</Link></li>
                  <li><Link to='/kursepro/'>KURSE & WORKSHOPS</Link></li>
                </ul>
              </li>
              <li>
                <Link to='/about/'>ÜBER UNS</Link>
              </li>
              <li>
                <Link to='/contact/'>KONTAKT</Link>
              </li>
              <li>
                <Link to='/referenzen/'>REFERENZEN</Link>
              </li>

            </ul>
          </nav>
          {/* </div> */}
          <div className={(styles.actions)}>

            <div className='social-menu'>
              <nav className={cn(styles.nav)}>
                <ul>
                  <li>
                    <a href='https://www.instagram.com/atelier_fontange' target='_blank' rel='noopener noreferrer'>
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a href='https://www.facebook.com/samundco' target='_blank' rel='noopener noreferrer'>
                      <FaFacebookSquare />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

          </div>
        </div>
      </div>

      <div className='mobile'>
        <button className={menuClassName} onClick={handleClick}>
          <span className='hamburger-box'>
            <span className='hamburger-inner' />
          </span>
        </button>

        <nav className={mobileMenu}>
          <div className='mobile-nav'>
            <div className='mobile-header'>
              <div className='mobile-logo'>
                <Link to='/'><Logo /></Link>
              </div>
              <button className={mobileMenuClassName} onClick={handleClick}>
                <span className='hamburger-box'>
                  <span className='hamburger-inner' />
                </span>
              </button>
            </div>
            <div className='mobile-menu-items'>

              <Link to='/' className='mobile-menu-items-link'><span className='dropdown-heading'>LEISTUNGEN</span><br /><span>für Privatpersonen</span></Link>
              <ul className='dropdown' aria-label='submenu'>
                <li><Link to='/zweithaar/' className='mobile-menu-items-link'>ZWEITHAAR & HAARERSATZ</Link></li>
                <li><Link to='/wedding/' className='mobile-menu-items-link'>HOCHZEITS- & FESTSTYLING</Link></li>
                <li><Link to='/special/' className='mobile-menu-items-link'>MAKE UP & SPECIAL EFFECTS - für Halloween, Fasching usw.</Link></li>
                <li><Link to='/fotoshoots/' className='mobile-menu-items-link'>FOTOSHOOTS</Link></li>
                <li><Link to='/kurse/' className='mobile-menu-items-link'>KURSE & WORKSHOPS</Link></li>
              </ul>
              <br /><br /><br />
              <Link to='/' className='mobile-menu-items-link'><span className='dropdown-heading'>LEISTUNGEN</span><br /><span>für Theater, Show & Film</span></Link>
              <ul className='dropdown' aria-label='submenu'>
                <li><Link to='/herstellung/' className='mobile-menu-items-link'>HERSTELLUNG</Link></li>
                <li><Link to='/verleih/' className='mobile-menu-items-link'>VERLEIH</Link></li>
                <li><Link to='/design/' className='mobile-menu-items-link'>DESIGN, ERSTELLUNG & ENTWICKLUNG</Link></li>
                <li><Link to='/kursepro/' className='mobile-menu-items-link'>KURSE & WORKSHOPS</Link></li>
              </ul>

              <Link to='/about/' className='mobile-menu-items-link'><span className='dropdown-heading'>ÜBER UNS</span></Link>
              <Link to='/contact/' className='mobile-menu-items-link'><span className='dropdown-heading'>KONTAKT</span></Link>
              <Link to='/referenzen/' className='mobile-menu-items-link'><span className='dropdown-heading'>REFERENZEN</span></Link>
            </div>
          </div>
        </nav>
      </div>

    </nav>
  )
}

export default Menu
