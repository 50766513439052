import React from 'react'
import { Link } from 'gatsby'
import '../styles/layout.css'
import styles from './layout.module.css'

const Footer = ({ pageLayout }) => (
  <>
    <footer className={`${styles.footer} ${pageLayout}`}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
          © {new Date().getFullYear()}, Built with ❤️ by <a href='https://www.runemoller.com' target='_blank'>RM</a>
          <span> | </span>
          <Link to='/impressum/'>  Impressum </Link>
          <span> | </span>
          <Link to='/datenschutz/'>  Datenschutz </Link>
        </div>
      </div>
    </footer>
  </>
)

export default Footer
